const p5 = require("p5");

let s = function (sketch) {
  //3d constants
  let angle = 0;
  let wrap;

  //3d plane objects properties
  const faces = [[0, 0, 0, "255, 255, 255"]];
  const edgeLength = 300;
  const animationFrames = 400;
  const transparency = 0.5;

  //resizing constants
  let sizeWidth;
  let sizeHeight;
  let sketchWindowWidth = sketch.windowWidth;

  //sketch resize logic to match css media queries
  if (sketchWindowWidth < 350 > 1) {
    sizeWidth = 275;
    sizeHeight = 275;
  }
  if (sketchWindowWidth > 351 < 450) {
    sizeWidth = 275;
    sizeHeight = 275;
  }
  if (sketchWindowWidth > 450 < 600) {
    sizeWidth = 300;
    sizeHeight = 300;
  }
  if (sketchWindowWidth > 600 < 750) {
    sizeWidth = 300;
    sizeHeight = 300;
  }
  if (sketchWindowWidth > 750 < 850) {
    sizeWidth = 300;
    sizeHeight = 300;
  }
  if (sketchWindowWidth > 850) {
    sizeWidth = 400;
    sizeHeight = 400;
  }

  sketch.setup = () => {
    let size = sketch.min(sizeWidth, sizeHeight);
    //initialise canvas with webgl allowing for 3d rendering
    sketch.createCanvas(size, size, sketch.WEBGL);
    //change angle mode from default radians to degrees
    sketch.angleMode(sketch.DEGREES);
    sketch.noStroke();
    wrap = sketch.createGraphics(400, 400);
    sketch.background(196, 196, 196, 20);
  };

  //window resize function
  function windowResized() {
    let size = sketch.min(sizeWidth, sizeHeight);
    sketch.resizeCanvas(size, size);
  }

  sketch.draw = () => {
    //camera with mouse controls which alter trail left by obj
    let locX = sketch.mouseX - sketch.width / 2;
    let locY = sketch.mouseY - sketch.height / 2;
    sketch.camera(sketch.mouseX, sketch.mouseY, 500);

    //lighting
    sketch.pointLight(255, 255, 255, 100, 0, 0);
    sketch.pointLight(255, 255, 255, 0, 100, 0);
    sketch.pointLight(255, 255, 255, 0, 0, 100);
    sketch.directionalLight(5, 0, 194, 1, 1, 1);
    sketch.directionalLight(0, 40, 194, -1, -1, -1);
    sketch.ambientLight(15);

    //action

    //interior 3d box shape allows the plane shape to appear like a photo frame
    sketch.push();
    sketch.texture(wrap);
    sketch.rotateX(angle);
    sketch.rotateY(angle);
    sketch.box(200);
    sketch.angle += 0.3;
    sketch.pop();

    //animate 3d plane shapes
    sketch.noStroke();
    sketch.rotateY(sketch.frameCount);
    //for each plane rendered, apply the elements in the faces array as colour and alpha values
    faces.forEach((/*face*/) => {
      //overridden if lighting methods are in use
      //sketch.fill(`rgba(${face[1]}, ${transparency})`);
      sketch.push();
      //random and the fact there's no background creates layers of plane shape which stay on canvas
      sketch.plane(sketch.random(edgeLength));
      sketch.pop();
    });
  };
};

//new p5 sketch instance on div with "theCanvas" ID
let myp5 = new p5(s, window.document.getElementById("theCanvas"));

//const declarations for elements which will be ~framed~ by a css box shadow
let frame = window.document.getElementById("theCanvas").style;
let bioFrame = window.document.getElementById("bio").style;
let windowFrame = window.document.getElementById("body").style;

//click events on each element will trigger a change to the colour of the box shadow
document
  .getElementById("theCanvas")
  //anonymous arrow function to change box shadow 'frame' colour on click events
  .addEventListener("click", (changeFrameColour) => {
    let r = Math.floor(Math.random() * 255);
    let g = Math.floor(Math.random() * 0);
    let b = Math.floor(Math.random() * 255);
    frame.boxShadow = `0px 0px 75px rgb(${r}, ${g}, ${b})`;
  });

document
  .getElementById("bio")
  .addEventListener("click", (changeFrameColour) => {
    let r = Math.floor(Math.random() * 0);
    let g = Math.floor(Math.random() * 255);
    let b = Math.floor(Math.random() * 255);
    bioFrame.boxShadow = `0px 0px 75px rgb(${r}, ${g}, ${b})`;
  });

document
  .getElementById("body")
  .addEventListener("click", (changeFrameColour) => {
    let r = Math.floor(Math.random() * 255);
    let g = Math.floor(Math.random() * 255);
    let b = Math.floor(Math.random() * 0);
    //shadow on body element set as inset so as to best frame the page
    windowFrame.boxShadow = `inset 0px 0px 95px rgb(${r}, ${g}, ${b})`;
  });
